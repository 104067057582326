<template>
  <div class="field-wrapper">
    <div class="header">
      <p>
        检索字段
        <!-- <span>从列表中选择项目进行搜索</span> -->
      </p>
      <span @click="$router.push('/help/docs/search/field')">字段说明</span>
    </div>
    <div class="content">
      <div class="item" v-for="(item, index) in field" :key="index">
        <div class="label"><img :src="getImg(index)" alt="">{{item.label}}</div>
        <div class="list">
          <div class="list-item" v-for="(it, id) in item.options" :key="id">
            <el-dropdown :tabindex="-3" trigger="click" role="menu" v-if="it.options" placement="bottom-end" :max-height="'300px'">
              <span class="el-dropdown-link">
                {{it.label}}
                <el-icon class="el-icon--right" style="position: relative; top: 2px;color:#8A9099">
                  <CaretBottom />
                </el-icon>
              </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item @mouseenter="preventFocus" v-for="(dpitem, dpindex) in it.options" @click.stop='emits("addField", it.value, `:"${dpitem?.value || dpitem}"`, true, it.isNum)' :key="dpindex">{{dpitem?.label || dpitem}}</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
            <p class="key" v-else @click="emits('addField', it.value, undefined, undefined, it.isNum)">{{it.label}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import field from './field.json'
import { defineEmits } from 'vue'
import { CaretBottom } from '@element-plus/icons-vue'
const emits = defineEmits(['addField'])
function getImg(field) {
  return require(`@/assets/img/svg/data/${field}.svg`)
}
const preventFocus = ev => {
  ev.stopPropagation()
}
</script>

<style lang="scss" scoped>
[data-theme='dark'] {
  .content .item .label img {
    filter: invert(80%);
  }
}
.field-wrapper {
  width: 100%;
  height: calc(100vh - 204px);
  border: 1px solid var(--color-border-2);
  .header {
    height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    border-bottom: 1px solid var(--color-border-2);
    p {
      font-size: 16px;
      font-weight: 500;
      color: var(--color-text-1);
      span {
        font-size: 12px;
        color: var(--color-text-2);
        margin-left: 8px;
      }
    }
    span {
      cursor: pointer;
      font-size: 14px;
      color: #00AB7A;
    }
  }
  .content {
    margin-top: 24px;
    display: flex;
    .item {
      flex: 1;
      padding-left: 24px;
      .label {
        margin-bottom: 12px;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        color: var(--color-text-1);
        display: flex;
        align-items: center;
        img {
          width: 18px;
          margin-right: 2px;
        }
      }
      &:last-child {
        .list {
           border-right: none;
        }
      }
      .list {
        height: calc(100vh - 319px);
        overflow-y: auto;
        border-right: 1px solid var(--color-border-2);
        .list-item {
          margin-bottom: 12px;
          line-height: 24px;
          cursor: pointer;
          p {
            margin-top: 6px;
            color: var(--color-text-1);
            &:hover {
              color: var(--color-primary);
            }
          }
          .el-dropdown {
            position: relative;
            top: 2px;
          }
        }
      }
    }
  }
}
</style>
