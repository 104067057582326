<template>
  <div class="wrapper">
    <!-- <div class="search-header">
      <span class="search-header-title">高级检索</span>
    </div> -->
    <div class="search-content">
      <history-vue @getList="getList" @delete="deleteHistory" :active="active" :list="searchList"></history-vue>
      <div class="right-part">
        <div class="top">
          <el-date-picker
            style="width: 320px"
            v-model="date"
            type="daterange"
            unlink-panels
            range-separator="～"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :shortcuts="shortcuts"
            :disabled-date="disabledDate"
            :size="size"
            :clearable="false"
          />
          <!-- <div id="editor-container"><pre><code>ip:" 192.168.2.34 " AND dropped_threat_level:" er454545ert " ip:" 192.168.2.34 " AND dropped_threat_level:" er454545ert " ip:" 192.168.2.34 " AND dropped_threat_level:" er454545ert "ip:" 192.168.2.34 " AND dropped_threat_level:" er454545ert " ip:" 192.168.2.34 " AND dropped_threat_level:" er454545ert " ip:" 192.168.2.34 " AND dropped_threat_level:" er454545ert "ip:" 192.168.2.34 " AND dropped_threat_level:" er454545ert " ip:" 192.168.2.34 " AND dropped_threat_level:" er454545ert " ip:" 192.168.2.34 " AND dropped_threat_level:" er454545ert "dropped_threat_level:" er454545ert "</code></pre></div> -->
          <!-- <QuillEditor :options="options"/> -->
          <!-- <div id="editor">
            <p>Core build with no theme, formatting, non-essential modules</p>
          </div> -->
          <!-- <el-input v-click-outside="onClickOutside" resize="none" ref="inputRef" v-model="keyword" clearable>
            <template #suffix>
              <svg-icon @click.stop="drawer=true" name="zhushi"></svg-icon>
            </template>
          </el-input> -->
          <el-popover
            ref="popoverRef"
            width="60%"
            :visible="visible"
            placement="bottom-start"
            :show-arrow="false"
            popper-class="symbols"
            :popper-options="popperOptions"
            :tabindex="-1"
          >
              <template #reference>
                <div class="input-wrapper">
                  <el-input
                    maxlength="1024"
                    type="textarea"
                    :autosize="rowsData"
                    @click="inputClick"
                    @mousedown="preventFocus"
                    @keydown.enter.prevent="search"
                    @focus="focus"
                    @blur="blur"
                    spellcheck="false"
                    v-click-outside="onClickOutside"
                    resize="none"
                    ref="inputRef"
                    v-model="keyword"
                    placeholder="请输入或选择内容检索"
                    clearable>
                  </el-input>
                  <span class="zhushi">
                    <div v-if="!visible" class="gradient"></div>
                    <template v-if="keyword.length">
                      <el-icon @click="clear"><Close /></el-icon>
                      <el-divider direction="vertical" />
                    </template>
                    <el-tooltip placement="top" :effect="$isDark() ? 'dark' : 'light'" :content="'高级检索语法说明'">
                      <svg-icon @click.stop="drawer=true" name="zhushi"></svg-icon>
                    </el-tooltip>
                  </span>
                </div>
              </template>
              <div class="logical">
                <span class="label">逻辑符</span>
                <div class="symbol">
                  <el-button style="font-size:12px;padding:2px 8px;height:24px;" @click="addBrakets('()')">( )</el-button>
                  <el-divider direction="vertical" />
                  <el-button style="font-size:12px;padding:2px 8px;height:24px;" v-for="(item,index) in [' AND ',' OR ',' NOT ']" :key="index" @click="addSymbol(item)">{{item}}</el-button>
                </div>
              </div>
              <div class="list-wrap">
                <div class="field logical" v-for="(item, index) in field" :key="index">
                  <span class="label">{{item.label}}</span>
                  <div class="symbol">
                    <span class="list-item" v-for="(it, id) in item.options" :key="id">
                      <!-- <el-dropdown role="navigation" tabindex="-1" popper-class="zindex" :teleported="false" trigger="click" v-if="it.options" placement="bottom-end" :max-height="'300px'">
                        <el-button>{{it.value}}<el-icon class="el-icon--right"><CaretBottom /></el-icon></el-button>
                        <template #dropdown>
                          <el-dropdown-menu>
                            <el-dropdown-item tabindex="-1" @mouseenter="preventFocus" v-for="(dpitem, dpindex) in it.options" @click='addFieldFocus(it.value, `:"${dpitem}"`, true)' :key="dpindex">{{dpitem}}</el-dropdown-item>
                          </el-dropdown-menu>
                        </template>
                      </el-dropdown> -->
                      <el-select
                        v-if="it.options"
                        popper-class="select"
                        :model-value="`${it.label}`"
                        :suffix-icon="CaretBottom"
                        @change="handleChange"
                        :placeholder="it.label"
                        :style="{ width: `${it.width}px` }"
                        class="field-select"
                      >
                        <el-option
                          v-for="(dpitem, dpindex) in it.options"
                          @click='addFieldFocus(it.value, `:"${dpitem?.value || dpitem}"`, true, it.isNum)'
                          :key="dpindex"
                          :label="dpitem?.label || dpitem"
                          :value="dpitem?.value || dpitem"
                        />
                      </el-select>
                      <el-button style="font-size:12px;padding:2px 8px;height:24px;" v-else @click="addFieldFocus(it.value, undefined, undefined, it.isNum)">{{it.label}}</el-button>
                    </span>
                  </div>
                </div>
              </div>
          </el-popover>
          <el-button type="primary" :disabled="(status === 'searching') || !keyword.trim().length" @click="search">查询</el-button>
        </div>
        <p class="time" v-if="curItem && status !== 'searching'">搜索时间：{{curItem.insert_time}}</p>
        <div class="explain" v-loading="loading">
          <search-fields v-if="status==='initial'" @addField="addFieldFocus"></search-fields>
          <div v-if="status==='searching'" class="searching">
            <img :src="require('@/assets/img/search/search-ing.png')" alt="">
            <span v-if="isWaitTimeout">系统正在努力检索中，请耐心等待</span>
            <span v-else>数据正在检索中，请稍侯...</span>
          </div>
          <div v-if="status==='failed'" class="searching failed">
            <no-data :showText="false"></no-data>
            <span>很遗憾，未找到搜索结果，可以尝试以下方法优化搜索以匹配到理想的数据</span>
            <span>1、扩大时间范围：当前选定的时间范围可能没有数据，可以尝试扩大时间范围再次进行搜索</span>
            <span>2、修改搜索条件：当前搜索条件可能存在错误，可以尝试查看<span @click="toDocs('/help/docs/search/field')" class="a">字段说明</span>和<span @click="toDocs('/help/docs/search/syntax')" class="a">语法说明</span>后再次搜索</span>
          </div>
          <div class="searching expired" v-if="status === 'expired'">
            <img :src="require('@/assets/img/search/expired.png')" alt="">
            <span>搜索结果已过期，请重新搜索 <span class="retry" @click="search">重试</span></span>
          </div>
          <div class="searching timeout" v-if="status === 'timeout'">
            <img :src="require('@/assets/img/search/timeout.png')" alt="">
            <span class="timeout_desc">抱歉当前搜索超时，请稍后再试，或者联系平台进行反馈。</span>
            <el-button type="primary" @click="$router.push('/help/feedback')">提交反馈</el-button>
          </div>
          <div v-if="status==='completed'" class="completed">
            <detail-vue :key="active" :record_id="active" @toggleList="toggleList"></detail-vue>
          </div>
          <div v-if="status==='completed'" class="lists" :class="{ 'active':expanded }">
            <div class="lists-title" @click="toggleList">
              <span>任务({{reportList.length || list_total}})</span>
              <!-- <el-tooltip class="item" :disabled="disabledTooltip" :effect="$isDark() ? 'dark' : 'light'" :content="expanded ? '收起任务' : '展开任务'" placement="top">
                <img :src="expanded ? require('@/assets/img/search/fold.svg') : require('@/assets/img/search/expand.svg')" alt="">
              </el-tooltip> -->
            </div>
            <div>
              <sand-no-data v-if="!list_total" :isFullscreen="true"/>
              <Authorization
                :gid="gid"
                authModule="list-module"
                :hasPayment="hasPermission_list"
                class="task-list"
                :showPoint="false"
                fullScreen
                v-else
              >
                <template v-slot:payment>
                  <TemplateList
                    :columnAttr="keyMap"
                    :dataList="reportList"
                    :isIndent="false"
                    :header="false"
                    class="newest-list"
                    :simple="true"
                  ></TemplateList>
                </template>
              </Authorization>
            </div>
          </div>
        </div>
      </div>
    </div>
    <backTo-top isContainer=".explain"></backTo-top>
    <explain-vue v-model:drawer="drawer" @addField="addField"></explain-vue>
    <!-- <upgrade-vue v-if="!showed"  v-model:show="isShowUpgrade"/> -->
  </div>
</template>

<script setup>
import field from './components/field.json'
import { ClickOutside as vClickOutside } from 'element-plus'
import { nextTick, onMounted, ref, computed, watch, onBeforeUnmount } from 'vue'
import { useRoute } from 'vue-router'
import SearchFields from './components/search-fields'
import explainVue from './components/explain.vue'
import historyVue from './components/history.vue'
import { Close, CaretBottom } from '@element-plus/icons-vue'
import { postAdSearch, getAdSearchList, getAdSearchReports, getAdSearchStatus, deleteSearchRecord } from 'services/search'
import emitter from '@/app/emitter.js'
// import { checkLogin } from 'services/auth'
import { useState, useGetters } from '@/hooks/useVuex'
import dayjs from 'dayjs'
import { message } from '@/hooks/useUI'
import TemplateList from '@/components/common/TemplateList'
import DetailVue from './detail/index'
// import UpgradeVue from './components/upgrade.vue'

// import hljs from 'highlight.js'
// import 'highlight.js/styles/atom-one-light.css' // 可以选择其他样式
// import { QuillEditor } from '@vueup/vue-quill'
// import '@vueup/vue-quill/dist/vue-quill.snow.css'
// const gid = Store.getters['user/gid']

emitter.on('ad-search', (v, type, isNum = false) => {
  function processString(input, key) {
    const backtick = ['registry', 'command_line', 'parent_cmdline']
    const addQuote = str => backtick.indexOf(key) > -1 ? `\`${str}\`` : `"${str}"`
    return addQuote(input)
  }
  const str = processString(v, type)
  addFieldFocus(type, `:${str}`, true, isNum)
})
let resizeObserver = null
onMounted(() => {
  // isLogin.value = await checkIsLogin()
  // eslint-disable-next-line no-undef
  // const quill = new Quill('#editor', {
  //   theme: 'snow',
  //   syntax: true,
  //   modules: {
  //     syntax: { hljs }
  //   }
  // })
  // console.log('[ quill ] >', quill)
  // const Delta = Quill.import('delta')
  // quill.setContents(
  //   new Delta()
  //     .insert('const language = "JavaScript";')
  //     .insert('\n', { 'code-block': 'javascript' })
  //     .insert('console.log("I love " + language + "!");')
  //     .insert('\n', { 'code-block': 'javascript' })
  // )

  // quill.setContents([
  //   { insert: 'Hello ' },
  //   { insert: 'World!', attributes: { color: 'red' } },
  //   { insert: '\n' }
  // ])
  // const textarea = inputRef.value.ref
  // const popover = this.$refs.popoverRef

  // if (!textarea || !popover) return
  // const textarea = inputRef.value.ref
  // textarea.addEventListener('input', reCalc())
  resizeObserver = new ResizeObserver(entries => {
    for (const entry of entries) {
      currentRows.value = entry.contentRect.height / 27
    }
  })
  if (inputRef.value.ref) {
    resizeObserver.observe(inputRef.value.ref)
  }
})
onBeforeUnmount(() => {
  if (resizeObserver && inputRef.value.ref) {
    resizeObserver.unobserve(inputRef.value.ref)
  }
})
const selectedValue = ref('')
const handleChange = (value) => {
  // displayText.value = `Selected: ${options.find(option => option.value === value).label}`
  // 重置 selectedValue 保持原值不变
  selectedValue.value = null
}
const keyMap = [
  {
    label: '环境_时间',
    props: 'env_time',
    span: 6
  },
  {
    label: '类型_分值',
    props: 'type_score',
    span: 4
  },
  {
    label: '名称_类型_标签',
    props: 'name_type_tags',
    span: 7
  },
  {
    label: '哈希',
    props: 'hash',
    span: 7
  }
]
// const options = ref(
//   {
//     debug: 'info',
//     modules: {
//       toolbar: null,
//       autoformat: true
//     },
//     syntax: true,
//     placeholder: '请输入内容搜索',
//     theme: 'snow'
//   }
// )\
const { userInfo } = useState('user', ['userInfo'])

watch(() => userInfo.value, v => {
  reset()
  getSearchList()
})

const route = useRoute()
const keyword = ref(route.query.keyword || '')
const status = ref('initial')
const inputRef = ref()
const currentRows = ref(1)
const drawer = ref(false)
const date = ref([dayjs().subtract(24, 'hour').format('YYYY-MM-DD HH:mm:ss'), dayjs().format('YYYY-MM-DD HH:mm:ss')])
const popoverRef = ref()
const visible = ref(false)
const rowsData = ref({ minRows: 1, maxRows: 1 })
const { gid } = useGetters('user', ['gid'])
const searchList = ref([])
const reportList = ref([])
const active = ref()
const loading = ref(false)
const expanded = ref(false)
const disabledTooltip = ref(false)
// const isShowUpgrade = ref(gid.value < 4)
// const showed = localStorage.getItem('isShowUpgrade')
const hasPermission_list = ref(false)
const list_total = ref(0)
const isWaitTimeout = ref(false)
const popperOptions = computed(() => {
  return {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, Math.min(currentRows.value, 3) * 27 - 22]
        }
      }
    ]
  }
})
const toggleList = v => {
  if (typeof v === 'boolean') {
    expanded.value = v
  }
  expanded.value = !expanded.value
  disabledTooltip.value = true
  nextTick(() => {
    disabledTooltip.value = false
  })
}
const disabledDate = (date) => {
  const today = new Date()
  const minDate = new Date()
  const maxDate = new Date()

  minDate.setDate(today.getDate() - 1800)
  maxDate.setDate(today.getDate())
  return date < minDate || date > maxDate
}

// const checkIsLogin = async () => {
//   const res = await checkLogin()
//   return res.is_login
// }
const deleteHistory = id => {
  deleteSearchRecord({
    record_id: id
  }).then(res => {
    if (!res?.code) {
      message('删除成功')
      deleteItem(id)
    } else {
      message('删除失败', 'error')
    }
  })
}
const deleteItem = (id) => {
  searchList.value = searchList.value.filter(item => item.id !== id)
  if (id === active.value) {
    const smallerItems = searchList.value.filter(item => item.id < id)
    const newActiveItem = smallerItems.length > 0 ? smallerItems.reduce((maxItem, item) => item.id > maxItem.id ? item : maxItem) : null
    active.value = newActiveItem ? newActiveItem.id : null
    if (!newActiveItem) {
      const largerItems = searchList.value.filter(item => item.id > id)
      active.value = largerItems.length > 0
        ? largerItems.reduce((minItem, item) => item.id < minItem.id ? item : minItem).id
        : null
    } else {
      active.value = newActiveItem.id
    }
  }
}
const reset = () => {
  status.value = 'initial'
  date.value = [dayjs().subtract(24, 'hour').format('YYYY-MM-DD HH:mm:ss'), dayjs().format('YYYY-MM-DD HH:mm:ss')]
  keyword.value = ''
}
let timer = null
let currentRequestId = null
watch(active, async id => {
  if (timer) {
    clearTimeout(timer)
    timer = null
  }
  isWaitTimeout.value = false
  if (!id) {
    reset()
    return
  }
  currentRequestId = id
  if (curItem.value) {
    keyword.value = curItem.value?.query || ''
    const { start_time, end_time } = curItem.value.conditions
    date.value = [start_time, end_time]
  }
  let res = await getAdSearchStatus({
    record_id: id
  })
  while ((res.status === 'created' || res.status === 'waiting' || res.code === 4023) && ((res?.id || res?.data?.id) === currentRequestId)) {
    if (res.code === 4023) {
      isWaitTimeout.value = true
    }
    status.value = 'searching'
    await new Promise(resolve => {
      timer = setTimeout(resolve, 3000)
    })
    if (id !== currentRequestId) return
    res = await getAdSearchStatus({
      record_id: id
    })
  }
  if (res.status === 'completed') {
    loading.value = true
  } else if (res.status === 'failed') {
    status.value = 'failed'
  } else if (res.code === 4021) {
    status.value = 'expired'
    loading.value = false
    return
  } else if (res.code === 4022) {
    status.value = 'timeout'
    loading.value = false
    return
  }
  getAdSearchReports({
    record_id: id
  }).then(res => {
    if (id !== currentRequestId) return
    loading.value = false
    if (res?.code === 4009) {
      status.value = 'searching'
    } else if (res?.code === 4020) {
      hasPermission_list.value = false
      list_total.value = res.total
      status.value = 'completed'
    } else if (res?.code === 5000 || res === null || !res?.length) {
      status.value = 'failed'
      reportList.value = []
    } else {
      reportList.value = res
      list_total.value = reportList.value
      status.value = 'completed'
    }
  })
})
const curItem = computed(() => {
  return searchList.value.find(item => item.id === active.value)
})
const getList = (id) => {
  if (id === active.value) return
  // loading.value = true
  active.value = id
}
const search = async () => {
  onClickOutside()
  if (status.value === 'searching') {
    message('请等待上个搜索结束后再进行搜索', 'error')
    return
  }
  if (!date.value) {
    message('请选择时间范围', 'error')
    return
  }
  if (!keyword.value.trim()) {
    message('请输入内容检索', 'error')
    return
  }
  let [start_time, end_time] = date.value
  start_time = dayjs(start_time).format('YYYY-MM-DD HH:mm:ss')
  end_time = dayjs(end_time).format('YYYY-MM-DD 23:59:59')
  try {
    const res = await postAdSearch({
      query: keyword.value,
      start_time,
      end_time
    })
    if (res.code === 4009) {
      message('请等待上个搜索结束后再进行搜索', 'error')
      return
    } else if (res.code === 1000) {
      message('当前搜索条件可能存在错误，可以尝试查看搜索条件和语法后再次搜索', 'error')
      return
    }
    status.value = 'searching'
    if (res?.id || res?.data?.id) {
      reportList.value = []
      await getSearchList()
      active.value = res?.id
    }
  } catch (e) {
    status.value = 'initial'
  }
}

// watch(status, v => {
//   if (v === 'searching') {
//     reportList.value = null
//   }
// })

const getSearchList = async () => {
  getAdSearchList().then(res => {
    if (res.length) {
      searchList.value = res
      searchList.value.forEach(item => {
        item.time = dayjs(item.insert_time).format('YYYY-MM')
      })
    }
  })
}
getSearchList()

const onClickOutside = e => {
  if (e && (e.target.classList.contains('symbols') || e.target.closest('.symbols') || e.target.closest('.zindex'))) {
    return false
  }
  rowsData.value = { minRows: 1, maxRows: 1 }
  nextTick().then(() => {
    inputRef.value.resizeTextarea()
  })
  visible.value = false
}
const inputClick = ev => {
  if (!gid.value) {
    emitter.emit('showLogin')
    ev.preventDefault()
    return
  }
  visible.value = true
}
const preventFocus = (event) => {
  if (!gid.value) {
    event.preventDefault()
  }
}
const clear = () => {
  keyword.value = ''
  inputRef.value.focus()
}
const focus = event => {
  if (!gid.value) {
    event.preventDefault()
    return
  }
  visible.value = false
  rowsData.value = { minRows: 1, maxRows: 3 }
  nextTick().then(() => {
    visible.value = true
    inputRef.value.resizeTextarea()
  })
}
// const blur = () => {
//   rowsData.value = { minRows: 1, maxRows: 1 }
//   nextTick().then(() => {
//     inputRef.value.resizeTextarea()
//   })
// }

const addField = v => {
  if (!gid.value) {
    emitter.emit('showLogin')
    return
  }
  if (keyword.value) v = `${keyword.value} ${v}`
  keyword.value = v
  inputRef.value.focus()
}
const addSymbol = v => {
  // if (keyword.value) v = `${keyword.value} ${v}`
  // keyword.value = v
  // inputRef.value.focus()
  insertAtCursor(v)
}
const addBrakets = v => {
  // if (keyword.value) v = `${keyword.value} ${v}`
  // keyword.value = v
  insertAtCursor(v)
  nextTick(() => {
    focusInside('()')
  })
}
const addFieldFocus = (v, q = ':""', isFocusEnd = false, isNum = false) => {
  if (!gid.value) {
    emitter.emit('showLogin')
    return
  }
  if (isNum) {
    q = q.replace(/"/g, '')
    isFocusEnd = true
  }
  let marks = '""'
  const backtick = ['registry', 'command_line', 'parent_cmdline']
  if (backtick.indexOf(v) > -1) {
    marks = '``'
    if (q === ':""') q = ':``'
  }
  v = `${v}${q}`
  // if (keyword.value) v = `${keyword.value} ${v}`
  // keyword.value = v
  insertAtCursor(v, !isFocusEnd, marks)
  // nextTick(() => {
  //   if (isFocusEnd) {
  //     setTimeout(() => {
  //       inputRef.value.focus()
  //     }, 500)
  //   } else {
  //     focusInside('""')
  //   }
  // })
}
const insertAtCursor = (newText, inside, marks = '""') => {
  const textarea = inputRef.value.$el.querySelector('textarea')

  // 获取光标位置
  const startPos = textarea.selectionStart
  const endPos = textarea.selectionEnd

  keyword.value = keyword.value.substring(0, startPos) + newText + keyword.value.substring(endPos)

  // 重设光标位置
  nextTick(() => {
    if (inside) {
      const cursorPosition = startPos + newText.indexOf(marks) + 1 // 定位到引号之间
      textarea.selectionStart = textarea.selectionEnd = cursorPosition
    } else {
      textarea.selectionStart = textarea.selectionEnd = startPos + newText.length
    }
    textarea.blur() // 填充完textarea光标定位到可视区域
    textarea.focus()
    // textarea.scrollTop = textarea.scrollHeight
  })
}
const focusInside = v => {
  const inputElement = inputRef.value.$el.querySelector('textarea')
  const lastQuoteIndex = keyword.value.lastIndexOf(v)
  if (lastQuoteIndex !== -1) {
    const cursorPosition = lastQuoteIndex + 1
    inputElement.setSelectionRange(cursorPosition, cursorPosition)
    inputElement.focus()
  }
}

const toDocs = href => {
  window.open(href, '_blank')
}

const shortcuts = [
  {
    text: '最近24小时',
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24)
      return [start, end]
    }
  },
  {
    text: '最近7天',
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
      return [start, end]
    }
  },
  {
    text: '最近30天',
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
      return [start, end]
    }
  },
  {
    text: '最近90天',
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
      return [start, end]
    }
  },
  {
    text: '最近180天',
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 180)
      return [start, end]
    }
  }
]
</script>
<style lang="scss">
@media screen and (max-height: 600px) {
  .symbols {
    max-height: 390px;
  }
}
.symbols {
  overflow-y: scroll;
  padding-bottom: 8px !important;
  z-index: 30000 !important;
  .logical {
    display: flex;
  }
  .field {
    margin-top: 14px;
  }
  .label {
    line-height: 32px;
    margin-right: 16px;
    width: 98px;
  }
  .symbol {
    flex: 1;
    position: relative;
    top: 4px;
    .list-item {
      margin: 0 8px 8px 0;
      display: inline-block;
      .el-dropdown {
        position: relative;
        top: -6px;
      }
      .field-select {
        .el-input {
          height: 24px;
        }
        .el-input__wrapper {
          font-size: 12px !important;
        }
      }
    }
  }
}
.zindex {
  z-index: 20000 !important;
}
.select {
  z-index: 30000 !important;
}
</style>
<style lang="scss" scoped>
.wrapper {
  min-height: calc(100vh - 116px);
  .search-header {
    line-height: 46px;
    height: 46px;
    padding: 2px 24px;
    display: flex;
    justify-content: space-between;
    .search-header-title {
      font-size: 18px;
      color: var(--color-text-1);
      font-weight: 500;
      font-family: '360shouhuType';
    }
  }
  .search-content {
    display: flex;
    background-color: var(--color-bg-1);
    border-radius: 4px;
    height: calc(100vh - 102px);
    margin: 24px 24px 0;
    // overflow-y: auto;
    :deep(.history-wrapper) {
      // &.isOpen + div {
      //   margin-left: 0px;
      // }
    }
    .right-part {
      flex: 1;
      // margin-left: -228px;
      transition: .5s;
      // overflow: hidden;
    }
    .top {
      display: flex;
      height: 40px;
      gap: 12px;
      justify-content: space-between;
      align-items: center;
      padding: 24px 24px 16px;
      :deep(.el-date-editor) {
        flex-grow: 0;
        height: 40px;
        .el-range__icon {
          right: 8px;
        }
      }
      // .el-textarea {
      //   flex: 1;
      //   padding: 8px;
      //   border-radius: 4px;
      //   height: 40px;
      //   font-size: 20px;
      //   :deep(.el-textarea__inner) {
      //     line-height: 30px;
      //     height: 40px;
      //   }
      // }
      .el-input {
        height: 40px;
        flex:1;
        :deep(.el-input__suffix) {
          font-size: 18px;
          color: var(--color-text-1);
          cursor: pointer;
          .el-input__clear {
            color: var(--color-text-1);
            font-size: 18px;
            border-right: 1px solid var(--color-bg-1);
            padding: 0 8px;
          }
          .el-input__suffix-inner {
            flex-direction: row-reverse;
            .svg-icon {
              &:hover {
                color: var(--color-primary);
              }
            }
          }
        }
      }
      .input-wrapper {
        width: 100%;
        height: 40px;
        position: relative;
        .el-textarea {
          height: 40px;
          :deep(textarea) {
            min-height: 40px !important;
            font-size: 14px;
            line-height: 26px;
            padding: 7px 68px 7px 11px;
            z-index: 1000;
            white-space: pre-wrap;      /* 保留换行符，但允许内容换行 */
            word-break: break-all;      /* 强制换行，长单词占满一行才换行 */
            overflow-wrap: break-word;  /* 防止长单词溢出 */
          }
        }
        .zhushi {
          z-index: 1001;
          position: absolute;
          display: flex;
          font-size: 18px;
          color: var(--color-text-1);
          cursor: pointer;
          right: 12px;
          top: 12px;
          .svg-icon, :deep(.el-icon) {
            &:hover {
              color: var(--color-primary);
            }
          }
          .gradient {
            width: 30px;
            height: 24px;
            margin-right: 6px;
            background: linear-gradient(to left, var(--color-bg-1), transparent);
          }
        }
      }
      button {
        height: 40px;
        width: 68px;
      }
    }
    .time {
      font-size: 12px;
      color: var(--color-text-2);
      margin: 0 0 12px;
      text-align: right;
      padding: 0 24px;
    }
    .explain {
      padding: 0 24px;
      position: relative;
      height: calc(100vh - 200px);
      width: calc(100% + 4px);
      box-sizing: border-box;
      overflow-y: scroll;
      overflow-x: hidden;
      .lists {
        transition: top .6s;
        z-index: 9;
        background: var(--color-bg-1);
        padding-bottom: 16px;
        &.active {
          overflow-y: auto;
          top: 24px;
          .lists-title {
            position: sticky;
            top: 0;
            z-index: 100;
          }
        }
        &-title {
          box-sizing: border-box;
          padding-left: 4px;
          font-size: 16px;
          font-weight: 500;
          color: var(--color-text-1);
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 50px;
          background: var(--color-bg-3);
          cursor: pointer;
          margin-top: 4px;
          span {
            font-size: 14px;
            height: 46px;
            padding: 0 16px;
            background: var(--color-bg-1);
            margin-top: 4px;
            display: flex;
            align-items: center;
            border-radius: 2px 2px 0px 0px;
            color: var(--color-primary);
          }
        }
      }
      .searching {
        width: 100%;
        height: calc(100vh - 230px);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img {
          width: 100px;
          height: 100px;
        }
        span {
          font-size: 14px;
          color: var(--color-text-3);
          &.retry {
            color: var(--color-primary);
            cursor: pointer;
          }
        }
        .a {
          color: var(--color-primary);
          font-size: 14px;
          margin-top: 8px;
          cursor: pointer;
        }
        &.failed {
          & > span {
            color: var(--color-text-3);
            padding: 6px;
          }
        }
        &.timeout {
          .timeout_desc {
            margin: 6px 0 16px;
          }
        }
      }
      .completed {
        // overflow-y: scroll;
        // padding: 0 8px;
        // height: calc(100vh - 238px);
        // width: calc(100% + 4px);
      }
    }
  }
}
[data-theme='dark'] {
  .search-content {
    :deep(.el-date-editor) {
      border: 1px solid #383a47;
      box-shadow: none;
    }
    .input-wrapper :deep(textarea) {
      border: 1px solid #383a47;
      box-shadow: none;
    }
  }
}
</style>
