<template>
  <div class="sandNetwork-wrap">
    <Loading class="analyse-loading" v-if="loading" :loading="loading" />
    <template v-else>
      <sand-no-data v-if="isEmpty(networkData) && !count" :isFullscreen="type === 'network'"/>
      <Authorization
        v-else
        :gid="gid"
        authModule="network-module"
        :hasPayment="!showAuth"
        class="network"
        :showPoint="false"
        fullScreen
      >
        <template v-slot:payment>
          <div class="sandNetwork-table">
            <div class="sandNetwork-header">
              <span
              class="header-col"
              :style="`
                width: ${item.width ? getWidth(item.width) : '0px'};
                flex-grow: ${item.width ? '0' : item.col ? item.col : '1'};
                text-align: ${item.align ? item.align : 'left'};
              `"
              v-for="(item,index) in header" :key="index">{{item.label}}</span>
            </div>
            <el-collapse v-model="collapseVal" accordion>
              <el-collapse-item
                v-for="(collapse, index) in networkData"
                :key='index'
                :name="index"
                :class="[type === 'network' ? '':'none-arrow']"
                @click="toDetail(index)"
              >
                <template #title>
                  <div class="sand-tableCollapse__header">
                    <div
                      class="sand-tableCollapse__header--item"
                    >
                      <span
                        class="sand-tableCollapse__header--text"
                        :style="`
                          width: ${item2.width ? getWidth(item2.width) : '0px'};
                          flex-grow: ${item2.width ? '0' : item2.col ? item2.col : '1'};
                          text-align: ${item2.align ? item2.align : 'left'};
                        `"
                        v-for="(item2,index2) in header"
                        :key="index2"
                        >
                        <el-tooltip :effect="$isDark() ? 'dark' : 'light'" :content="$t('dialog.detect.label')" placement="top">
                          <svg-icon class="detect" @click.stop="detect(collapse.uri)" v-if="item2.type === 'detect'" name="tijiaojiance"></svg-icon>
                        </el-tooltip>
                          <span class="sand-tableCollapse__header--tags" :class="{'margin12' : props.type === 'network'}" v-if="item2.prop === 'severity'" :style="{color:riskStyle(collapse[item2.prop]).color,borderColor:riskStyle(collapse[item2.prop]).border}">{{riskStyle(collapse[item2.prop]).name}}</span>
                          <span class="font" v-else>{{collapse[item2.prop]}}</span>
                        </span>
                    </div>
                  </div>
                </template>
                <template v-if="type === 'network'">
                  <div class="content" v-for="(modules, idx) in detailModule" :key="idx">
                    <template v-if="modules.type === 'label'">
                      <div class="h2">{{modules.name}}</div>
                      <div class="detail">
                        <div class="item" v-for="(val, key, index) in modules.map" :key="index">
                          <div class="label">{{val}}</div>
                          <div class="value" v-if="key === 'severity'" :style="{color: riskStyle(collapse[key]).color}">{{riskStyle(collapse[key]).name}}</div>
                          <div class="value" style="flex: 1;" v-else-if="key === 'raw'">
                            <div class="code-pre" style="width: 100%;">
                              <pre :key="Math.random() + idx">{{ collapse[key] }}</pre>
                            </div>
                          </div>
                          <div class="value" v-else>
                            <div v-if="key in searchKeyMap" :class="{font: key === 'uri'}" v-html="collapse[key]" v-copy="searchKeyMap[key]"></div>
                            <div v-else :class="{font: key === 'uri'}" v-html="collapse[key]"></div>
                            <!-- <el-tooltip :effect="$isDark() ? 'dark' : 'light'" :content="$t('ti')" placement="top">
                              <svg-icon v-if="toTiProp.includes(key)" name="sousuoti" @click="toTi(collapse[key])"></svg-icon>
                            </el-tooltip> -->
                          </div>
                        </div>
                      </div>
                    </template>
                    <template v-if="modules.type === 'code'">
                      <div class="h2">{{modules.name}}</div>
                      <div class="code-pre">
                        <pre>{{ collapse[modules.prop] }}</pre>
                      </div>
                    </template>
                  </div>
                </template>
                <template v-if="type === 'overview'">
                  <span class="row-col" v-for="(item2,index2) in header" :key="index2">{{collapse[item2.prop]}}</span>
                </template>
              </el-collapse-item>
            </el-collapse>
          </div>
          <div class="pagination-wrap">
            <Pagination
              @handleSizeChange="handleSizeChange"
              @handleCurrentChange="handleCurrentChange"
              :totalSize="totalCount"
              :current_page="currentPage"
              :pageSizes="[10, 50]"
              :page-size="pageSize"
            />
          </div>
        </template>
      </Authorization>
    </template>
    <redetect
      v-if="state.redetect"
      v-model:show="state.redetect"
      redetectType="url"
      :url="detect_url"
    ></redetect>
  </div>
</template>

<script setup>
import { getAdSearchModuleDetail } from 'services/search'
import Pagination from '@/components/common/Pagination'
import sandNoData from '@/components/report/sandNoData'
import redetect from '@/components/report/redetect'
import { reactive, ref, toRefs, defineProps, computed, watch, onMounted, unref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import emitter from 'app/emitter'
import { t } from 'app/i18n'
import { useGetters } from '@/hooks/useVuex'
import { checkLogin } from 'services/auth'
const route = useRoute()
const router = useRouter()
const store = useStore()

const { page, index } = useGetters('report', ['page', 'index'])

const { gid } = useGetters('user', ['gid'])
const showAuth = ref(false)
const props = defineProps({
  tab: {
    type: String,
    default: 'dns'
  },
  header: {
    type: Array
  },
  totalCount: {
    type: Number
  },
  record_id: {
    type: Number
  },
  type: {
    type: String
  }
})
const count = computed(() => {
  return window.networkTabCount[props.tab]
})
const keyMap = {
  hosts: 'network_hosts',
  dns: 'domains',
  http: 'http_info',
  https: 'https_info'
}

const state = reactive({
  networkData: [],
  pageSize: 10,
  currentPage: page.value,
  loading: true,
  // toTiProp: ['src_ip', 'dst_ip', 'ip', 'area_name', 'dst', 'src'],
  toTiProp: [],
  searchKeyMap: {
    ip: 'ip',
    country: 'country',
    area_name: 'domain',
    uri: 'uri'
  },
  collapseVal: props.type === 'network' ? index.value : null,
  redetect: false,
  detect_url: ''
})

onMounted(() => getTaskReportNetworkFn())
const { networkData, pageSize, currentPage, loading, searchKeyMap, collapseVal, detect_url } = toRefs(state)
const { header, totalCount, record_id, type, tab } = toRefs(props)

const taskId = computed(() => route.params.taskId || record_id.value)

const $tt = v => t(`report.network.${v}`)
const detailModule = computed(() => {
  switch (props.tab) {
    case 'hosts':
      return [{
        ipProp: 'ip',
        name: $tt('hosts.info'),
        type: 'label',
        map: {
          ip: $tt('hosts.ip'),
          country: $tt('hosts.country'),
          city: $tt('hosts.city'),
          isp: 'ISP'
        }
      }]
    case 'traffic.alerts':
      return [
        {
          ipProp: 'dst_ip',
          name: $tt('traffic.alerts.info'),
          type: 'label',
          map: {
            src_ip: $tt('traffic.alerts.src_ip'),
            src_port: $tt('traffic.alerts.src_port'),
            dst_ip: $tt('traffic.alerts.dst_ip'),
            dst_port: $tt('tcp.dport'),
            protocol: $tt('traffic.alerts.protocol')
          }
        },
        {
          name: $tt('traffic.alerts.detect'),
          type: 'label',
          map: {
            signature: $tt('traffic.alerts.signature'),
            category: $tt('traffic.alerts.category'),
            severity: $tt('traffic.alerts.severity'),
            timestamp: $tt('traffic.alerts.timestamp')
          }
        },
        {
          name: $tt('traffic.alerts.payload'),
          type: 'code',
          prop: 'payload'
        }
      ]
    case 'dns':
      return [
        {
          ipProp: 'area_name',
          name: $tt('dns.info'),
          type: 'label',
          map: {
            area_name: $tt('dns.area_name'),
            response: $tt('dns.response'),
            analyse: $tt('dns.analyse')
          }
        }
      ]
    case 'http':
    case 'https':
      return [
        {
          name: $tt('http.info'),
          type: 'label',
          map: {
            uri: 'URI'
          }
        },
        {
          name: $tt('http.content'),
          type: 'code',
          prop: 'data'
        }
      ]
    case 'tcp':
    case 'udp':
      return [{
        ipProp: 'dst',
        name: $tt('tcp.info'),
        type: 'label',
        map: {
          src: $tt('tcp.src'),
          sport: $tt('tcp.sport'),
          dst: $tt('tcp.dst'),
          dport: $tt('tcp.dport')
        }
      }]
    case 'icmp':
      return [{
        ipProp: 'dst',
        name: $tt('icmp.info'),
        type: 'label',
        map: {
          src: $tt('icmp.src'),
          dst: $tt('icmp.dst'),
          type: $tt('icmp.type'),
          data: $tt('icmp.data')
        }
      }]
    case 'ftp':
      return [
        {
          ipProp: 'dst',
          name: $tt('icmp.info'),
          type: 'label',
          map: {
            src: $tt('icmp.src'),
            sport: $tt('tcp.sport'),
            dst: $tt('icmp.dst'),
            dport: $tt('tcp.dport')
          }
        },
        {
          type: 'code',
          name: $tt('ftp.command'),
          prop: 'cmd'
        }
      ]
    case 'smtp':
      return [{
        ipProp: 'dst',
        name: $tt('smtp.info'),
        type: 'label',
        map: {
          dst: $tt('icmp.dst'),
          raw: $tt('smtp.raw')
        }
      }]
    case 'irc':
      return [{
        name: $tt('irc.info'),
        type: 'label',
        map: {
          command: $tt('irc.command'),
          type: $tt('irc.type'),
          params: $tt('irc.params')
        }
      }]
  }
  return ''
})
watch(tab, () => {
  handleCurrentChange(1, true)
})
watch(taskId, () => {
  handleCurrentChange(1, true)
})
watch(() => router.currentRoute.value.path, (toPath) => {
  // handleCurrentChange(page, true)
  state.collapseVal = index.value
})

// 分析概览网络分析切换tab loading滚动条闪动
// watch(networkData, (v) => {
//   if (props.type === 'network') return
//   nextTick(() => {
//     document.querySelector('.sandNetwork-wrap').style.height = `${v.length * 49 + 104}px`
//   })
// })

function isEmpty (target) {
  const type = Object.prototype.toString.call(target).slice(8, -1).toLowerCase()
  switch (type) {
    case 'object':
    case 'array':
    case 'string':
      return Object.keys(target).length === 0
    case 'undefined':
    case 'null':
      return true
    default:
      return false
  }
}
function detect(url) {
  checkLogin().then((res) => {
    if (!res.is_login) {
      emitter.emit('showLogin')
    } else {
      state.detect_url = url
      state.redetect = true
    }
  })
}
function toDetail(index) {
  if (props.type === 'network') return false
  store.commit('report/SET_NETWORK_DETAIL', { tab: props.tab, page: currentPage.value, index })
  emitter.emit('tabChange', 'network')
}
// function toUnicode(val) {
//   var strInput = val

//   var nInputLength = strInput.length

//   if (nInputLength % 2 === 0) { // 当输入够偶数位；
//     var StrHex = ''

//     for (var i = 0; i < nInputLength; i = i + 2) {
//       var str = strInput.substr(i, 2) // 16进制；

//       // StrHex = StrHex + .toString(16);

//       var n = parseInt(str, 16)// 10进制；

//       StrHex = StrHex + String.fromCharCode(n)
//     }

//     return StrHex
//   }
// }
function riskStyle(data) {
  switch (data) {
    case 3:
      // return { name: t('risk.low'), border: '#5a5e6d', color: '#b8bbcc' }
      return { name: t('risk.low'), border: 'var(--color-info-border)', color: 'var(--color-info-text)' }
    case 2:
      return { name: t('risk.middle'), border: '#c2aa6f', color: '#d9b12f' }
    case 1:
      return { name: t('risk.high'), border: '#eb726c', color: '#ff6a63' }
    default:
      return { name: t('risk.not_detected'), border: '', color: '#5a5e6d' }
  }
}
async function getTaskReportNetworkFn (isload) {
  if (isload) { loading.value = true }
  if (!taskId.value) return
  await getAdSearchModuleDetail({
    page: currentPage.value,
    size: pageSize.value,
    protocol: keyMap[props.tab],
    record_id: props.record_id
    // lang: isZh() ? 'zh' : 'en'
  }).then(res => {
    loading.value = false
    if (res.code === 4020) {
      showAuth.value = true
      return
    }
    if (!isEmpty(res)) {
      if (props.tab === 'dns') {
        networkData.value = res.map(item => {
          return {
            area_name: item.request,
            response: item.answers.map(item => `${item.type} ${item.data}`).join('\n'),
            analyse: item.ip
          }
        })
      } else {
        networkData.value = res
      }
    } else {
      networkData.value = []
    }
  })
}
function handleSizeChange (size) {
  pageSize.value = size
  handleCurrentChange(1)
}
function handleCurrentChange (page, isload) {
  currentPage.value = unref(page)
  getTaskReportNetworkFn(isload)
  collapseVal.value = ''
}
</script>

<style lang="scss" scoped>
[lang='en'] {
  .sandNetwork-wrap .sandNetwork-table .content .detail .item .label{
    width: 110px;
  }
}
  .sandNetwork-wrap {
    // min-height: calc(100vh - 368px);
    display: flex;
    .analyse-loading {
      height: auto;
      padding-top: 80px;
    }
    :deep(.sand-noData) {
      // height: calc(100vh - 374px);
      height: auto;
      padding: 56px 0;
    }
    :deep(.network) {
      flex: 1;
    }
    .sandNetwork-table {
      color: var(--color-text-2);
      font-size: 14px;
      .sandNetwork-header {
        display: flex;
        height: 48px;
        line-height: 48px;
        color: var(--color-text-2);
        color: var(--color-text-2);
        background-color: var(--color-bg-3);
        // padding: 0 45px 0 15px;
        padding: 0 46px 0 15px;
        .header-col {
          padding: 10px;
          line-height: 28px;
          flex: 1;
        }
      }
      .none-arrow {
        :deep(.el-collapse-item__arrow) {
          display: none;
        }
      }
      & :deep(.el-collapse), & :deep(.el-collapse-item__header), & :deep(.el-collapse-item__wrap) {
        border: 0;
        background: transparent;
      }
      & :deep(.el-collapse-item__header) {
        height: 48px;
        font-weight: normal;
        background: var(--detect-config-bg);
        border-bottom: 1px solid var(--color-border-2);
        &.is-active {
          .sand-tableCollapse__header .sand-tableCollapse__header--item .sand-tableCollapse__header--text {
            color: var(--color-text-1);
          }
        }
        .el-collapse-item__arrow {
          color: var(--color-text-3);
          &.is-active {
            color: var(--color-text-1);
          }
        }
        &:hover {
          & .el-icon-arrow-right:before {
            filter: brightness(--svg-filter2);
          }
        }
      }
      .sand-tableCollapse__header--text {
        width: 100%;
        line-height: 18px;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: var(--color-text-2);
        height: 48px;
        display: flex;
        align-items: center;
        &>span {
          padding-left: 10px;
        }
        .font {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .detect {
          margin: 0 auto;
        }
        svg {
          font-size: 16px;
          &:hover {
            color: var(--color-text-1);
          }
        }
      }
      .sand-tableCollapse__header--tags {
        border: 1px solid;
        border-radius: 2px;
        font-size: 12px;
        text-align: center;
        padding: 4px 12px;
        margin-left: 24px;
      }
      .margin12 {
        margin-left: 12px;
      }
      .sandNetwork-row {
        display: flex;
        border-bottom: 1px solid var(--color-border-2);
        // bd_color(color-sand-border);
        align-items: center;
        .row-col {
          flex: 1;
          padding: 10px;
          line-height: 20px;
          white-space: break-spaces;
          word-break: break-all;
        }
      }
      .sand-tableCollapse__header {
        width: calc(100% - 65px);
        .sand-tableCollapse__header--item {
          width: 100%;
          padding-left: 15px;
          display: flex;
          span {
            // padding: 10px;
          }
        }
      }
      :deep(.el-collapse) {
        .el-collapse-item__content {
          padding: 0;
        }
      }
      .content {
        padding: 20px;
        background: var(--color-bg-3);
        .h2 {
          font-size: 16px;
          color: var(--color-text-1);
          height: 48px;
          line-height: 48px;
          box-shadow: 0 1px 0 0 var(--color-border-2);
        }
        .detail {
          margin-top: 20px;
          .item {
            display: flex;
            margin-top: 10px;
            .label {
              font-size: 14px;
              color: var(--color-text-3);
              font-weight: 500;
              min-width: 80px;
              margin-right: 40px;
            }
            .value {
              flex: 1;
              width: calc(100% - 120px);
              font-size: 14px;
              color: var(--color-text-2);
              display: flex;
              align-items: center;
              svg {
                margin-left: 6px;
                font-size: 16px;
                color: var(--color-text-3);
                cursor:pointer;
                &:hover {
                  color: var(--color-text-1);
                }
              }
              div {
                white-space: pre-wrap;
              }
              .font {
                word-break: break-all;
              }
            }
          }
        }
        .code-pre {
          overflow-y: auto;
          padding: 0 16px;
          height: 200px;
          border: 1px solid var(--color-border-2);
          border-radius: 2px;
          color: var( --color-unkown-text);
          pre {
            white-space: pre-wrap;
            line-height: 20px;
            // white-space: pre-line;
            word-break: break-all;
            font-size: 14px;
            // font-family: 'CONSOLA';
          }
        }
      }
    }
    .pagination-wrap {
      padding: 10px 0px 10px 10px;
      margin-right: -20px;
      text-align: right;
    }
  }
</style>
