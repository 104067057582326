<template>
  <div class="history-wrapper" :class="{isOpen: open}">
    <el-input
      v-model="input"
      size="large"
      placeholder="搜索历史记录"
      :suffix-icon="Search"
      @input="handleInput"
      ref="searchInputRef"
    />
    <div class="list" v-if="Object.keys(obj).length">
      <div class="day" v-for="(item, index) in obj" :key="index">
        <p class="title">{{index}}</p>
        <p class="item" v-for="(it, id) in item" :key="id">
          <!-- <span class="content" :class="{active: active === it.id}" @click="emit('getList', it.id, it.query)" v-html="highlight(it.query)"></span> -->
          <el-tooltip
            popper-class="item-tooltip"
            effect="light"
            :content="it.query"
            placement="right"
            :disabled="!isOverflowing(`${item}-${id}`)"
          >
            <span class="content" @mouseover="e => handleMouseOver(e,`${item}-${id}`)" :class="{active: active === it.id}" @click="emit('getList', it.id, it.query)" v-html="highlight(it.query)"></span>
          </el-tooltip>
          <span class="delete" @click="emit('delete', it.id)">
            <svg-icon name="shanchu"></svg-icon>
          </span>
        </p>
      </div>
    </div>
    <div class="list align-center" v-else><no-data data="暂无历史记录"></no-data></div>
    <el-tooltip
      popper-class="fold-tooltip"
      effect="light"
      :content="open ? '收起历史记录' : '展开历史记录'"
      placement="top"
      :disabled="disabled"
    >
      <div class="arrow" @click="clickArrow"><div :class="{isOpen: open}">></div></div>
    </el-tooltip>
  </div>
</template>

<script setup>
import { Search } from '@element-plus/icons-vue'
import { ref, defineProps, computed, defineEmits, nextTick } from 'vue'
const props = defineProps({
  list: {
    type: Array,
    required: true
  },
  active: {
    type: Number,
    required: false
  }
})
const overflowItems = ref({})
const disabled = ref(false)
const clickArrow = () => {
  open.value = !open.value
  disabled.value = true
  nextTick(() => {
    disabled.value = false
  })
}
const handleMouseOver = (event, id) => {
  const element = event.target
  overflowItems.value[id] = element.scrollWidth > element.clientWidth
}
const isOverflowing = (id) => {
  return overflowItems.value[id]
}
const emit = defineEmits(['getList', 'delete'])
const obj = computed(() => {
  const fn = (accumulator, currentItem) => {
    const dateKey = currentItem.time
    if (!accumulator[dateKey]) {
      accumulator[dateKey] = []
    }
    accumulator[dateKey].push(currentItem)
    return accumulator
  }
  if (input.value) {
    const queryLower = input.value.toLowerCase()
    return props.list.filter(item =>
      item.query.toLowerCase().includes(queryLower)
    ).reduce(fn, {})
  }
  return props.list.reduce(fn, {})
})
const searchInputRef = ref(null)

const highlight = (result) => {
  const queryValue = input.value
  if (!queryValue) return result
  const regex = new RegExp(`(${queryValue})`, 'gi')
  return result.replace(regex, '<span class="highlight">$1</span>')
}
const input = ref('')
const open = ref(false)
</script>
<style>
.highlight {
  background-color: yellow;
  font-weight: bold;
}
.item-tooltip {
  max-width: 300px;
  white-space: normal;
  box-sizing: border-box;
  z-index: 5001 !important;
}
.fold-tooltip {
  z-index: 10 !important;
}
[data-theme='dark'] {
  .arrow {
    background-image: url('~@/assets/img/arrow-dark.png') !important;
  }
}
</style>
<style lang="scss" scoped>
.history-wrapper {
  width: 0;
  position: relative;
  transition: .5s;
  border: 0;
  margin: 24px 0 24px 0px;
  &.isOpen {
    width: 216px;
    border-right: 1px solid var(--color-border-2);
    padding-right: 24px;
    margin: 24px 0 0px 24px;
    :deep(.el-input) {
      margin-top: 0px;
      opacity: 1;
    }
    .list {
      width: 228px;
    }
  }
  :deep(.el-input) {
    margin-top: 0px;
    transition: none !important;
    opacity: 0;
  }
  .list {
    margin-top: 16px;
    overflow-y: scroll;
    width: 0;
    transition: .5s;
    height: calc(100vh - 228px);
    &.align-center {
      display: flex;
      align-items: center;
    }
  }
  .day {
    width: 216px;
    margin: 16px 0 8px 0;
    font-size: 14px;
    .title {
      font-weight: 500;
      color: var(--color-text-1);
    }
    .item {
      color: var(--color-text-2);
      display: flex;
      padding: 8px 0;
      margin: 0;
      cursor: pointer;
      // justify-content: space-between;
      align-items: center;
      &:hover {
        .delete {
          display: block;
        }
      }
      .content {
        display: inline-block;
        max-width: 198px;
        // word-break: break-all;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 18px;
        &:hover {
          color: var(--color-primary);
        }
        &.active {
          color: var(--color-primary);
        }
        .highlight {
          color: var(--color-primary);
        }
      }
      .delete {
        display: none;
        margin-left: 2px;
        svg {
          &:hover {
            color: var(--color-primary);
          }
        }
      }
    }
  }
  .arrow {
    width: 12px;
    height: 32px;
    position: absolute;
    right: -12px;
    top: 50%;
    transform: translateY(-50%);
    background-image: url('~@/assets/img/arrow.png');
    background-size: contain;
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    div {
      color: #8A9099;
      transition: all 0.5s;
      width: 8px;
      height: 16px;
      line-height: 1;
      &.isOpen {
        transform: rotateZ(180deg)
      }
    }
  }
}
</style>
